.circle-one {
  top: -5vh;
  left: -30vw;
  background: linear-gradient(
    -19.7deg,
    /* Approximation from gradientTransform */ rgba(252, 100, 3, 0.95) 0%,
    /* Starting color with opacity */ rgba(255, 205, 0, 1) 100%
      /* Ending color */
  );
}

.circle-two {
  top: -15vh;
  left: -25vw;
  background: linear-gradient(
    47.1deg,
    /* Approximation from gradientTransform */ rgba(0, 71, 201, 1) 0%,
    /* Starting color */ rgba(13, 173, 187, 1) 100% /* Ending color */
  );
}

.circle-three {
  top: -15vh;
  left: 25vw;
  background: linear-gradient(45deg, #ff2e00, #ff8000); /* Linear gradient */
}
