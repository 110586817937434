/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  color-scheme: only light;
}

body {
  @apply font-parkinsasn;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #251333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100vh; /* Ensures the container fits the viewport */
}
