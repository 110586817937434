.marquee-container {
  /* Center in parent and limit width */
  max-width: 100%;

  position: relative;
  overflow: hidden;

  /* Vibrant, glossy gradient background */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.1),
    /* Orange Red */ rgba(255, 255, 255, 0.1) /* Dodger Blue */
  );

  /* Enhance the glossy effect with higher opacity */
  backdrop-filter: blur(10px);

  /* Spacing & text styling */
  padding: 0.75rem 1.5rem;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.1rem;
  white-space: nowrap; /* Prevent text wrapping */

  /* Enhanced box-shadow for depth */
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
}

.marquee-content {
  display: inline-block;
  /* Start text “off-screen” from the right */
  padding-left: 10%;
  animation: marquee-scroll 8s linear infinite;
}

/* Keyframes for the marquee animation */
@keyframes marquee-scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Optional: Add a subtle gradient overlay for extra gloss */
/* .marquee-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    pointer-events: none;
    border-radius: 0.75rem;
  } */
